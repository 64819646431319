import '@ifca-ui/core/src/styles/style.scss'
import 'assets/styles/app.scss'
import 'assets/styles/tailwind.scss'
import 'react-circular-progressbar/dist/styles.css'

import { ApolloProvider } from '@apollo/client'
import { Snackbar, Spinner, Theme } from '@ifca-ui/core'
import { Button, CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { client } from 'PropertyClient'
import AppContext from 'containers/context/Context'
import { RootInitialState, RootReducer } from 'containers/context/Reducer'
import { loadingObservable } from 'helpers/Messages/loadingHelper'
import { snackBarObservable } from 'helpers/snackBarSubjectHelper'
import { SyntheticEvent, useEffect, useReducer } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { MainRoutes } from '../routes/RootRoutes'

export default function App() {
  const [rootState, rootDispatch] = useReducer(RootReducer, RootInitialState)

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    rootDispatch({
      type: 'snackBar',
      payload: {
        open: false,
        message: '',
      },
    })
  }

  useEffect(() => {
    loadingObservable().subscribe(x => {
      rootDispatch({
        type: 'loading',
        payload: x,
      })
    })

    snackBarObservable().subscribe(x => {
      if (x) {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: x,
          },
        })
      }
    })
  }, [])

  return (
    <ThemeProvider theme={Theme}>
      <ApolloProvider client={client}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AppContext.Provider value={{ rootState, rootDispatch }}>
            {rootState.loading && <Spinner />}
            <BrowserRouter>
              {/* <NavigationProvider /> */}
              <MainRoutes />
            </BrowserRouter>
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              message={rootState.snackBar?.message}
              open={rootState.snackBar?.open}
              onClose={handleClose}
              autoHideDuration={5000}
              action={
                <Button color="secondary" size="small" onClick={handleClose}>
                  DISMISS
                </Button>
              }
            />
          </AppContext.Provider>
        </LocalizationProvider>
      </ApolloProvider>
    </ThemeProvider>
  )
}
