import { RowFlex } from '@ifca-ui/core'
import { Typography, useMediaQuery } from '@mui/material'
import { padding } from '@mui/system'

interface ReminderProps {
  reminder1: number
  reminder2: number
  reminder3: number
}

const ReminderCounts = ({ reminder1, reminder2, reminder3 }: ReminderProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  return (
    <>
      <RowFlex fullWidth mainAxisAlignment="space-between">
        <Typography className="text-desc">
          {isMobile ? 'R1:' : 'Reminder 1:'}
          <span className="search-bar-count-f" style={{ margin: 2 }}>
            {reminder1}
          </span>
        </Typography>
        <Typography className="text-desc">
          {isMobile ? 'R2:' : 'Reminder 2:'}
          <span className="search-bar-count-f" style={{ margin: 2 }}>
            {reminder2}
          </span>
        </Typography>
        <Typography className="text-desc">
          {isMobile ? 'R3:' : 'Reminder 3:'}
          <span className="search-bar-count-f" style={{ margin: 2 }}>
            {reminder3}
          </span>
        </Typography>
      </RowFlex>
    </>
  )
}

export default ReminderCounts
