import React, { lazy } from 'react'
import { ExpFilStateProvider } from './ExpansionFilterState'
import { Outlet } from 'react-router'
// import ConsolidateEinvoicesDetail from './ConsolidateEinvoicesDetail'

const ConsolidateEinvoices = lazy(() => import('./ConsolidateEinvoices'))
const ConsolidateEinvoicesDetail = lazy(
  () => import('./ConsolidateEinvoicesDetail')
)

const ConsolidateEinvoicesRoutes = [
  {
    path: 'consolidate-einvoices',
    element: (
      <ExpFilStateProvider>
        <Outlet />
      </ExpFilStateProvider>
    ),
    children: [
      {
        path: '',
        element: <ConsolidateEinvoices />,
      },
      {
        path: 'details',
        element: <ConsolidateEinvoicesDetail />,
      },
    ],
  },
]

export default ConsolidateEinvoicesRoutes
