import { yupResolver } from '@hookform/resolvers/yup'
import {
  ColumnFlex,
  Container,
  EmptyState,
  List,
  ListItem,
  ListItemText,
  RowFlex,
  RowGrid,
  SubContainerX,
  Subheader,
  SubheaderText,
  Wrap,
} from '@ifca-ui/core'
import {
  HouseOutlined,
  KeyboardArrowRight,
  Person,
  Phone,
} from '@mui/icons-material'
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import HideX from 'components/General/HideX'
import UIlabelX from 'components/General/UILabelX'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useGetContactListLazyQuery,
  useGetPAsummaryLazyQuery,
  useGetUnitListLazyQuery,
  useProjectDetailQuery,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/yup'
import { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useLocation, useNavigate, useParams } from 'react-router'
import * as yup from 'yup'
import usePAdata from './usePAdata'
interface IPAmoduleForm {
  purchaser: any
  unit: any
}
const PurchaserAccountsSubmenu = () => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)

  let navigate = useNavigate()
  const { navBack, navTo, navData } = useNav()
  let location = useLocation()
  let stateData: any = location?.state
  const { projectID }: any = useParams()
  const [showPage, setShowPage] = useState<boolean>(false)
  const path = `/${projectID}/purchaser-accounts`
  const [saleID, setSaleID] = useState<string>('')
  const [contactId, setContactId] = useState(null)
  const [unitId, setUnitId] = useState(null)
  const [purchaserDetail, setPurchaserDetail] = useState(null)

  //#region GraphQL
  const { data: { projectDetail } = { projectDetail: null } } =
    useProjectDetailQuery({ variables: { id: projectID } })

  const [
    fetchPAmoduleSummary,
    {
      data: { getPAsummary } = {
        getPAsummary: null,
      },
    },
  ] = useGetPAsummaryLazyQuery({
    onCompleted: data => {
      setShowPage(true)
      if (navData || stateData?.saleId != null) {
        setValue('purchaser', data.getPAsummary?.contactData)
        setValue('unit', data.getPAsummary?.unitData)
        setContactId(data.getPAsummary?.contactData?.contactId)
        setUnitId(data.getPAsummary?.unitData.unitId)
        setPurchaserDetail(data.getPAsummary?.contactData)
      }
    },
  })

  const [
    getContactListData,
    { data: { getContactList = [] } = {}, loading: getContactListLoading },
  ] = useGetContactListLazyQuery({
    onCompleted: data => {
      if (unitId == null) {
        setValue('unit', null)
        setUnitId(null)
      }
      if (data.getContactList.length === 1) {
        setValue('purchaser', data.getContactList[0])
        setContactId(data.getContactList[0].contactId)
      } else {
        let contact = data.getContactList.find(x => x.contactId === contactId)
        setValue('purchaser', contact)
      }
    },
  })

  const [getUnitListData, { data: { getUnitList = [] } = {} }] =
    useGetUnitListLazyQuery({
      onCompleted: data => {
        if (contactId == null) {
          setValue('purchaser', null)
          setContactId(null)
        }

        if (data.getUnitList.length === 1) {
          setValue('unit', data.getUnitList[0])
          setSaleID(data.getUnitList[0].saleId)
          setUnitId(data.getUnitList[0].unitId)
        }
      },
    })

  //#endregion

  //#region header/subheader
  useEffect(() => {
    getContactListData({ variables: { unitId: '' } })
    getUnitListData({ variables: { contactId: '' } })

    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
            // navTo(`/${projectID}/sales-cancellation/form/add`)
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Purchaser Accounts',
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail])
  //#endregion

  const { submenuList } = usePAdata(getPAsummary, saleID, showPage)

  const PAmoduleFormSchema = yup.object().shape({
    purchaser: CommonYupValidation.requireArray('Purchaser is required.'),
    unit: CommonYupValidation.requireArray('Unit is required.'),
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm<IPAmoduleForm>({
    defaultValues: {
      purchaser: null,
      unit: null,
    },
    mode: 'onSubmit',
    resolver: yupResolver(PAmoduleFormSchema),
  })

  useEffect(() => {
    if (saleID && unitId && !showPage) {
      fetchPAmoduleSummary({
        variables: {
          saleId: saleID,
        },
      })
    }
  }, [saleID, unitId])

  useEffect(() => {
    if (stateData?.saleId != null && !showPage) {
      setSaleID(stateData.saleId)
      fetchPAmoduleSummary({
        variables: {
          saleId: stateData.saleId,
        },
      })
    }
  }, [stateData])

  useEffect(() => {
    if (navData && !showPage) {
      setSaleID(navData?.['saleId'])
      fetchPAmoduleSummary({
        variables: {
          saleId: navData?.['saleId'],
        },
      })
    }
  }, [navData])

  const isDesktop = useMediaQuery(useTheme().breakpoints.up('sm'), {
    defaultMatches: true,
  })

  return (
    <>
      <SubContainerX>
        <Subheader>
          <SubheaderText
            primary={
              <ColumnFlex gap={'5px'}>
                <RowGrid
                  gridTemplateColumns={isDesktop ? '1fr 1fr' : ''}
                  gap="10px"
                >
                  <Controller
                    control={control}
                    name="purchaser"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Autocomplete
                        className="fontSizeSelect"
                        disablePortal
                        fullWidth
                        options={getContactList}
                        getOptionLabel={option =>
                          !!option?.['isCompany']
                            ? `${option?.['companyName']} (${option['purchaserName']})`
                            : option['purchaserName']
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.contactId === value.contactId
                        }
                        renderOption={(props, option) => (
                          <ListItem
                            {...props}
                            key={option?.contactId} // overwrite original key from props
                            style={{ padding: '0' }}
                          >
                            <div>
                              {!!option?.isCompany ? (
                                <ColumnFlex>
                                  <Wrap colGap="4px" rowGap="4px">
                                    <UIlabelX
                                      children={option?.companyName}
                                      fontWeight={'font-bold'}
                                      fontSize="text-[12px]"
                                    />
                                    <UIlabelX
                                      children={`(${option?.purchaserName})`}
                                      fontWeight={'font-bold'}
                                      fontSize="text-[12px]"
                                    />
                                  </Wrap>
                                  <Wrap colGap="4px" rowGap="4px">
                                    <UIlabelX
                                      children={'Company Reg No: '}
                                      fontWeight={'font-light'}
                                      fontSize="text-[12px]"
                                    />
                                    <UIlabelX
                                      children={option?.companyRegNo}
                                      fontWeight={'font-light'}
                                      fontSize="text-[12px]"
                                    />
                                    {!!option.officeTel && (
                                      <RowFlex gap="4px">
                                        <div></div>
                                        <UIlabelX
                                          children={'|'}
                                          fontWeight={'font-light'}
                                          fontSize="text-[12px]"
                                        />
                                        <UIlabelX
                                          children={option?.officeTel}
                                          fontWeight={'font-light'}
                                          fontSize="text-[12px]"
                                        />
                                      </RowFlex>
                                    )}
                                  </Wrap>
                                </ColumnFlex>
                              ) : (
                                <ColumnFlex>
                                  <UIlabelX
                                    children={option?.purchaserName}
                                    fontWeight={'font-bold'}
                                    fontSize="text-[12px]"
                                  />
                                  <Wrap>
                                    <UIlabelX
                                      children={`IC No: ${option?.icNo}`}
                                      fontWeight={'font-light'}
                                      fontSize="text-[12px]"
                                    />
                                    {!!option.officeTel && (
                                      <RowFlex gap="4px">
                                        <div></div>
                                        <UIlabelX
                                          children={'|'}
                                          fontWeight={'font-light'}
                                          fontSize="text-[12px]"
                                        />
                                        <UIlabelX
                                          children={option?.officeTel}
                                          fontWeight={'font-light'}
                                          fontSize="text-[12px]"
                                        />
                                      </RowFlex>
                                    )}
                                  </Wrap>
                                </ColumnFlex>
                              )}
                            </div>
                          </ListItem>
                        )}
                        onChange={(event, newValue) => {
                          onChange(newValue)
                          setShowPage(false)
                          if (newValue) {
                            let contact = getContactList.find(
                              x => x.contactId === newValue?.contactId
                            )
                            setPurchaserDetail(contact)
                            setContactId(newValue?.contactId)
                            getUnitListData({
                              variables: { contactId: newValue?.contactId },
                            })
                          } else {
                            getContactListData({ variables: { unitId: '' } })
                            getUnitListData({ variables: { contactId: '' } })
                            setContactId(null)
                            setUnitId(null)
                          }
                        }}
                        value={value}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="standard"
                            margin="none"
                            autoComplete="off"
                            value={value}
                            helperText={errors?.purchaser?.message}
                            error={errors?.purchaser ? true : false}
                            required
                            className="selectPAstyle"
                            InputProps={{
                              ...params.InputProps,
                              placeholder: 'Purchaser',
                              className: 'fontSizeSelect',
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Person color="primary" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="unit"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Autocomplete
                        className="fontSizeSelect"
                        disablePortal
                        fullWidth
                        options={getUnitList.map(option => ({
                          ...option,
                          key: option.unitId + option.saleId,
                        }))}
                        renderOption={(props, option) => (
                          <li {...props} key={option.unitId + option.saleId}>
                            {`${option?.['unitNo']} (${option?.['unitStatus']})`}
                          </li>
                        )}
                        getOptionLabel={option =>
                          `${option?.['unitNo']} (${option?.['unitStatus']})`
                        }
                        isOptionEqualToValue={(option, value) =>
                          option?.saleId === value?.saleId
                        }
                        onChange={(event, newValue) => {
                          if (newValue?.saleId === saleID) return
                          onChange(newValue)
                          setShowPage(false)
                          if (newValue) {
                            let contact = getContactList.find(
                              x => x.contactId === newValue?.contactId
                            )
                            setPurchaserDetail(contact)
                            setUnitId(newValue?.unitId)
                            getContactListData({
                              variables: { unitId: newValue?.unitId },
                            })
                            setSaleID(newValue?.saleId)
                            setContactId(newValue?.contactId)
                          } else {
                            getContactListData({ variables: { unitId: '' } })
                            getUnitListData({ variables: { contactId: '' } })
                            setContactId(null)
                            setUnitId(null)
                            setSaleID(null)
                          }
                        }}
                        value={value}
                        renderInput={params => (
                          <TextField
                            {...params}
                            // multiline
                            variant="standard"
                            margin="none"
                            autoComplete="off"
                            value={value}
                            helperText={errors?.unit?.message}
                            error={errors?.unit ? true : false}
                            required
                            InputProps={{
                              ...params.InputProps,
                              placeholder: 'Unit',
                              className: 'fontSizeSelect',
                              startAdornment: (
                                <InputAdornment position="start">
                                  <HouseOutlined color="primary" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </RowGrid>
                {purchaserDetail && contactId && (
                  <RowFlex>
                    <HideX>
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    </HideX>
                    {purchaserDetail?.isCompany ? (
                      <RowFlex className="text-desc">
                        <div>
                          <span>Company Reg No: </span>
                          <span>{purchaserDetail?.companyRegNo}</span>
                        </div>
                        {purchaserDetail.officeTel && (
                          <RowFlex gap="5px">
                            <span></span>
                            <span>|</span>
                            <div className="text-icon">
                              <Phone />
                              {purchaserDetail.officeTel}
                            </div>
                          </RowFlex>
                        )}
                      </RowFlex>
                    ) : (
                      <RowFlex gap="5px" className="text-desc">
                        <span>
                          <span>IC No: </span>
                          {purchaserDetail.icNo}
                        </span>
                        <span>|</span>
                        <div className="text-icon">
                          <Phone />
                          {purchaserDetail.phoneNo}
                        </div>
                      </RowFlex>
                    )}
                  </RowFlex>
                )}
              </ColumnFlex>
            }
          />
        </Subheader>
      </SubContainerX>
      <Container>
        {showPage ? (
          <>
            <List>
              {submenuList?.map(el => {
                return (
                  <ListItem
                    key={el.title}
                    secondaryAction={
                      <IconButton>
                        <KeyboardArrowRight />
                      </IconButton>
                    }
                    onClick={() => {
                      navTo(el?.path, null, { saleId: saleID })
                    }}
                  >
                    <ListItemText
                      primary={
                        <>
                          <RowFlex fullWidth mainAxisAlignment="space-between">
                            <ColumnFlex>
                              <Typography
                                component="span"
                                className="text-xsTitle"
                                variant="inherit"
                              >
                                {el?.title}
                              </Typography>
                            </ColumnFlex>
                            <ColumnFlex>
                              <Tooltip
                                title={el?.tooltipTitle || 'Total Charges'}
                                placement="left"
                              >
                                <Typography
                                  component="span"
                                  color={el?.rightTextColor}
                                  className="text-xsTitle"
                                >
                                  {el.amount < 0 && '('}
                                  <NumberFormat
                                    value={el?.amount}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                  />
                                  {el.amount < 0 && ')'}
                                </Typography>
                              </Tooltip>
                              {''}
                              <Typography
                                component="span"
                                color={el?.rightTextColor}
                                className="text-xsTitle"
                              >
                                {el?.date}
                              </Typography>
                            </ColumnFlex>
                          </RowFlex>
                          <RowFlex fullWidth mainAxisAlignment="space-between">
                            <ColumnFlex>
                              <Typography
                                component="span"
                                className="text-xsTitle"
                                variant="inherit"
                              >
                                {el?.description}
                              </Typography>
                            </ColumnFlex>
                            <ColumnFlex>
                              <Tooltip
                                title={
                                  el?.secondTooltipTitle || 'Total Unallocated'
                                }
                                placement="left"
                              >
                                <Typography
                                  component="span"
                                  color={el?.secondRightTextColor}
                                  className="text-xsTitle"
                                >
                                  {el.secondAmount < 0 && '('}
                                  <NumberFormat
                                    value={el?.secondAmount}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                  />
                                  {el.secondAmount < 0 && ')'}
                                </Typography>
                              </Tooltip>
                            </ColumnFlex>
                          </RowFlex>
                        </>
                      }
                    />
                  </ListItem>
                )
              })}
            </List>
          </>
        ) : (
          <>
            <EmptyState title="No Record found" />
          </>
        )}
      </Container>
    </>
  )
}

export default PurchaserAccountsSubmenu
