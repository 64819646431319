import { TableRow, styled } from '@mui/material'

//#region Styled Row
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // padding: '12px 16px',

  '&:nth-of-type(even)': {
    backgroundColor: '#fef1de',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))
//#endregion

export default StyledTableRow
