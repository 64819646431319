import React, { lazy } from 'react'

const EcompanySettings = lazy(() => import('./EcompanySettings'))
const EcompanyForm = lazy(() => import('./EcompanyForm'))

const EcompanySettingsRoutes = [
  {
    path: 'company-settings',
    children: [
      {
        path: '',
        element: <EcompanySettings />,
      },
      {
        path: 'ecompany-form/add',
        element: <EcompanyForm mode="add" />,
      },
      {
        path: 'ecompany-form/:id/edit',
        element: <EcompanyForm mode="edit" />,
      },
    ],
  },
]

export default EcompanySettingsRoutes
