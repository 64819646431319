import { useEffect, useState } from 'react'

const useProjectId = () => {
  const [projectId, setProjectId] = useState<string | undefined>(
    () =>
      localStorage.getItem('latestProject') ||
      sessionStorage.getItem('latestProject') ||
      undefined
  )

  useEffect(() => {
    if (projectId) {
      localStorage.setItem('latestProject', projectId)
      sessionStorage.setItem('latestProject', projectId)
    }
  }, [projectId])

  return { projectId }
}

export default useProjectId
