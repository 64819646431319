import { createContext, ReactNode, useContext, useMemo, useState } from 'react'
import { subsaleInfoProps } from './SubsaleInfoForm'
import { purchaserInfoProps } from './SubsaleNewPurchaserFormNew'

interface purchaserFullInfoState extends purchaserInfoProps {
  id?: string
  contactExt: any
  isCompany: boolean
}

interface masterInfoState {
  Genders: any
  MaritalStatus: any
  Races: any
  Salutations: any
  Nationalities: any
}

export interface subsaleCoFinancingState {
  jointBuyerData: any[]
  endFinancierData: any[]
}

export interface SubsaleState {
  SubsaleInfo: subsaleInfoProps
  PurchaserInfo: purchaserFullInfoState
  CoFinancingInfo: subsaleCoFinancingState
  masterInfo: masterInfoState
  currentPage: '1' | '2' | '3' | 'EndFinancier' | 'JointBuyer'
  mode: 'add' | 'edit' | ''
  formMode: 'add' | 'edit' | ''
  editIndex: ''
}

interface SubsaleContextType {
  SubsaleState: SubsaleState
  setSubsaleState: React.Dispatch<React.SetStateAction<SubsaleState>>
}

const SubsaleContext = createContext<SubsaleContextType | undefined>(undefined)

export const SubsaleProvider = ({ children }: { children: ReactNode }) => {
  const [SubsaleState, setSubsaleState] = useState<SubsaleState>({
    SubsaleInfo: null,
    PurchaserInfo: null,
    CoFinancingInfo: {
      jointBuyerData: [],
      endFinancierData: [],
    },
    currentPage: '1',
    mode: '',
    formMode: '',
    editIndex: '',
    masterInfo: {
      Genders: null,
      MaritalStatus: null,
      Races: null,
      Salutations: null,
      Nationalities: null,
    },
  })
  const contextValue = useMemo(
    () => ({ SubsaleState, setSubsaleState }),
    [SubsaleState]
  )

  return (
    <SubsaleContext.Provider value={contextValue}>
      {children}
    </SubsaleContext.Provider>
  )
}

export const useSubsaleState = () => {
  const context = useContext(SubsaleContext)
  if (context === undefined) {
    throw new Error('useSubsaleState must be used within an SubsaleProvider')
  }
  return context
}
