import { lazy } from 'react'
import { Outlet, RouteObject } from 'react-router-dom'
import CompanySettingsRoutes from './CompanySettings/Routes'
import BillItemSettingsRoutes from './BillItemSettings/Routes'
import TenantEinvoicesRoutes from './TenantEinvoices/Routes'
import ConsolidateEinvoicesRoutes from './ConsolidateEinvoices/Routes'
import { EinvoiceProvider } from './EinvoiceProvider'
// import EinvoiceSubmenu from './Einvoice/BookingListBIroutes'

const EinvoiceSubmenu = lazy(() => import('./EinvoiceSubmenu'))

export const EINVOICE_PATH = {
  EINVOICE_SUBMENU: '/einvoice',
}

const EinvoiceRoutes: RouteObject[] = [
  {
    path: '',
    element: <EinvoiceSubmenu />,
  },
  {
    path: '',
    element: (
      <EinvoiceProvider>
        <Outlet />
      </EinvoiceProvider>
    ),
    children: [
      ...TenantEinvoicesRoutes,
      ...ConsolidateEinvoicesRoutes,
      ...BillItemSettingsRoutes,
      ...CompanySettingsRoutes,
    ],
  },
]

export default EinvoiceRoutes
