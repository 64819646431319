import { useEffect, useState } from 'react'
import io from 'socket.io-client'

const useSocket = () => {
  const [socket, setSocket] = useState(null)
  const [connectStatus, setConnectStatus] = useState(false) // Add connectStatus state
  const environment = import.meta.env
  const token = sessionStorage.getItem('tokenKey') || ''

  useEffect(() => {
    if (environment.VITE_ENV_TYPE == 'Local') return
    const newSocket = io(environment.VITE_SOCKET_HOST, {
      path: environment.VITE_ENV_TYPE !== 'Local' ? '/ws' : '',
      auth: {
        token,
      },
    })
    setSocket(newSocket)
    newSocket.on('connect', () => {
      setConnectStatus(newSocket.connected)
      logUserOnlineStatus(newSocket)
    })

    newSocket.on('reconnect', () => {
      logUserOnlineStatus(newSocket)
    })
  }, [])

  const logUserOnlineStatus = socket => {
    if (!socket) return
    socket.emit('login', token)
  }

  const socketDisconnection = () => {
    socket?.disconnect()
  }

  return { logUserOnlineStatus, socketDisconnection, connectStatus }
}

export default useSocket
