import { Expansion, ExpansionDetails, ExpansionSummary } from '@ifca-ui/core'
import { CalendarMonthRounded, ExpandMore } from '@mui/icons-material'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { getUserProfile } from 'UserData'
import { useGetUserRoleIdsQuery } from 'generated/graphql'
import { useCheckboxGroup } from 'helpers/Hooks/useCheckboxGroup'
import { formatDate } from 'helpers/StringNumberFunction/formatDate'
import { amtStr } from 'helpers/numFormatter'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import { useContext, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { AttachmentsListComponent } from './AttachmentsListComponent'
import { PAdraftListComponent } from './PAdraftListComponent'
import AppContext, { AppContextProps } from 'containers/context/Context'

export interface props {
  listData?: []
  tableRef?: String
  refetch?: any
}
export const PAsubmitListComponent = props => {
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [testList, setTestList] = useState<any>([])
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const {
    listData,
    tableRef,
    handleStatusMenuItemClick,
    idList,
    setIdList,
    path,
  } = props

  const style = {
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
    },
  }

  const { data: { getUserRoleIds } = { getUserRoleIds: null } } =
    useGetUserRoleIdsQuery({})

  // #region useCheckboxGroup
  const {
    checkedItems,
    checkedIds,
    parentChecked,
    parentIndeterminate,
    toggleChild,
    toggleParent,
    resetCheckedItems,
  } = useCheckboxGroup({
    initialValues: [],
  })

  useEffect(() => {
    if (listData?.length > 0) {
      const newCheckedItems = listData?.filter(
        x =>
          x.approvalRoleId === null ||
          getUserRoleIds?.includes(x.approvalRoleId)
      )
      resetCheckedItems(newCheckedItems.map(x => x.id))
      setParentCheckboxDisabled(newCheckedItems.length === 0)
    }
  }, [listData])
  const [parentCheckboxDisabled, setParentCheckboxDisabled] = useState(false)
  // #endregion

  return (
    <>
      {!rootState.profileData?.superUser && (
        <Typography padding="0 12px" component="div">
          <FormControlLabel
            onClick={e => {
              if (parentCheckboxDisabled) {
                setSnackBar(
                  'User has no approval permission on any available records'
                )
              }
            }}
            control={
              <Checkbox
                checked={parentChecked || false}
                indeterminate={parentIndeterminate}
                onChange={e => {
                  const value = e.target.checked
                  toggleParent(value)
                  setIdList(value ? Object.keys(checkedItems) : []) // checkedItems alrd filtered role permission
                }}
                disabled={parentCheckboxDisabled}
              />
            }
            label="Select All"
            className="text-desc"
          />
        </Typography>
      )}

      {listData?.map((v, index) => {
        const isDisabled =
          !getUserRoleIds?.includes(v.approvalRoleId) &&
          v.approvalRoleId !== null
        return (
          <Expansion key={v.id}>
            <ExpansionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel-content"
              id="expansion-panel"
            >
              {!rootState.profileData?.superUser && (
                <span
                  style={{ lineHeight: 1 }}
                  onClick={e => {
                    e.stopPropagation()
                    if (isDisabled) {
                      setSnackBar(
                        'User has no approval permission on this record'
                      )
                    }
                  }}
                >
                  <Checkbox
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    size="small"
                    color="primary"
                    sx={{
                      padding: '0px 6px 0px 12px',
                      marginBottom: '12px',
                    }}
                    onClick={e => {
                      e.stopPropagation()
                    }}
                    onChange={e => {
                      if (!isDisabled) {
                        const findIndex = idList?.indexOf(v.id)
                        if (findIndex > -1) {
                          idList.splice(findIndex, 1)
                        } else {
                          idList.push(v.id)
                        }
                        setIdList([...idList])
                        toggleChild(v.id)
                      }
                    }}
                    checked={checkedItems[v.id] || false}
                    disabled={isDisabled}
                  />
                </span>
              )}
              <PAdraftListComponent
                firstLine={{
                  left: {
                    // isCheckbox: true,
                    icon: <CalendarMonthRounded />,
                    text: `${formatDate(v?.documentDate) ?? '-'} | ${
                      formatDate(v?.dueDate) ?? '-'
                    } `,
                  },
                  right: {
                    amount: Math.abs(v?.documentAmount),
                    color: 'primary',
                    tooltip: 'Document Amount',
                  },
                }}
                secondLine={{
                  left: {
                    text: `${v?.description ? v?.description : '-'}`,
                  },
                  right: {
                    amount: Math.abs(v?.unallocatedAmount),
                    color: 'secondary',
                    tooltip: 'Unallocated Amount',
                  },
                }}
              />
            </ExpansionSummary>
            <ExpansionDetails>
              <div style={style.gridContainer}>
                <Box width="100%" boxSizing="border-box" marginBottom="14px">
                  <Typography
                    noWrap
                    component="div"
                    color="grey.600"
                    fontSize="10px"
                    fontWeight={400}
                    lineHeight="16px"
                  >
                    Request By
                  </Typography>
                  <Typography
                    noWrap
                    component="div"
                    color="common.black"
                    fontSize="12px"
                    fontWeight={500}
                    lineHeight="16px"
                  >
                    {v?.requestUserData ? v?.requestUserData?.name : '-'}
                  </Typography>
                </Box>
                <Box width="50%" boxSizing="border-box" marginBottom="14px">
                  <Typography
                    noWrap
                    component="div"
                    color="grey.600"
                    fontSize="10px"
                    fontWeight={400}
                    lineHeight="16px"
                  >
                    Amount
                  </Typography>

                  <Typography
                    noWrap
                    component="div"
                    color="common.black"
                    fontSize="12px"
                    fontWeight={500}
                    lineHeight="16px"
                  >
                    {amtStr(
                      Math.abs(v?.documentAmount) - v?.taxData?.totalTaxAmount
                    )}
                  </Typography>
                </Box>
                {v?.billingDetailTaxData && (
                  <>
                    <Box width="50%" boxSizing="border-box" marginBottom="14px">
                      <Typography
                        noWrap
                        component="div"
                        color="grey.600"
                        fontSize="10px"
                        fontWeight={400}
                        lineHeight="16px"
                      >
                        Tax
                      </Typography>
                      <Typography
                        noWrap
                        component="div"
                        color="common.black"
                        fontSize="12px"
                        fontWeight={500}
                        lineHeight="16px"
                      >
                        {v?.billingDetailTaxData?.taxSchemeName ?? '-'}
                      </Typography>
                    </Box>
                    <Box width="50%" boxSizing="border-box" marginBottom="14px">
                      <Typography
                        noWrap
                        component="div"
                        color="grey.600"
                        fontSize="10px"
                        fontWeight={400}
                        lineHeight="16px"
                      >
                        Tax Amount
                      </Typography>
                      <Typography
                        noWrap
                        component="div"
                        color="common.black"
                        fontSize="12px"
                        fontWeight={500}
                        lineHeight="16px"
                      >
                        {amtStr(v?.taxData?.totalTaxAmount)}
                      </Typography>
                    </Box>
                  </>
                )}

                <Box width="50%" boxSizing="border-box" marginBottom="14px">
                  <Typography
                    noWrap
                    component="div"
                    color="grey.600"
                    fontSize="10px"
                    fontWeight={400}
                    lineHeight="16px"
                  >
                    Total Amount
                  </Typography>
                  <Typography
                    noWrap
                    component="div"
                    color="common.black"
                    fontSize="12px"
                    fontWeight={500}
                    lineHeight="16px"
                  >
                    <NumberFormat
                      value={Math.abs(v?.documentAmount)}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                    />
                  </Typography>
                </Box>
                <Box width="100%" boxSizing="border-box" marginBottom="14px">
                  <Typography
                    noWrap
                    component="div"
                    color="grey.600"
                    fontSize="10px"
                    fontWeight={400}
                    lineHeight="16px"
                  >
                    Remarks
                  </Typography>
                  <Typography
                    noWrap
                    component="div"
                    color="common.black"
                    fontSize="12px"
                    fontWeight={500}
                    lineHeight="16px"
                  >
                    {v?.description == '' || null ? '-' : v?.description}
                  </Typography>
                </Box>
                <Box width="50%" boxSizing="border-box" marginBottom="14px">
                  <Typography
                    noWrap
                    component="div"
                    color="grey.600"
                    fontSize="10px"
                    fontWeight={400}
                    lineHeight="16px"
                  >
                    Submitted Date
                  </Typography>
                  <Typography
                    noWrap
                    component="div"
                    color="common.black"
                    fontSize="12px"
                    fontWeight={500}
                    lineHeight="16px"
                    overflow={'visible'}
                  >
                    {v?.submittedDate ? formatDate(v?.submittedDate) : '-'}
                  </Typography>
                </Box>
                <Box width="50%" boxSizing="border-box" marginBottom="14px">
                  <Typography
                    noWrap
                    component="div"
                    color="grey.600"
                    fontSize="10px"
                    fontWeight={400}
                    lineHeight="16px"
                  >
                    Submitted By
                  </Typography>
                  <Typography
                    noWrap
                    component="div"
                    color="common.black"
                    fontSize="12px"
                    fontWeight={500}
                    lineHeight="16px"
                    overflow={'visible'}
                  >
                    {v?.submitUserData?.name}
                  </Typography>
                </Box>
                <Box width="50%" boxSizing="border-box" marginBottom="14px">
                  <Typography
                    noWrap
                    component="div"
                    color="grey.600"
                    fontSize="10px"
                    fontWeight={400}
                    lineHeight="16px"
                  >
                    Attachments
                  </Typography>
                  <Typography
                    noWrap
                    component="div"
                    color="common.black"
                    fontSize="12px"
                    fontWeight={500}
                    lineHeight="16px"
                    overflow={'visible'}
                    className="text-underline text-xsTitle"
                    onClick={e => {
                      setOpenDialog(true)
                      setTestList(v?.attachment)
                    }}
                  >
                    {v?.attachment?.length}
                  </Typography>
                </Box>
                {v?.billItem && (
                  <Box width="50%" boxSizing="border-box" marginBottom="14px">
                    <Typography
                      noWrap
                      component="div"
                      color="grey.600"
                      fontSize="10px"
                      fontWeight={400}
                      lineHeight="16px"
                    >
                      Transaction Type
                    </Typography>
                    <Typography
                      noWrap
                      component="div"
                      color="common.black"
                      fontSize="12px"
                      fontWeight={500}
                      lineHeight="16px"
                      overflow={'visible'}
                    >
                      {v?.billItem?.name}
                    </Typography>
                  </Box>
                )}
              </div>
            </ExpansionDetails>
          </Expansion>
        )
      })}
      <AttachmentsListComponent
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        listData={testList}
      />
    </>
  )
}
