import { lazy } from 'react'
import { Outlet, RouteObject } from 'react-router'
import { SetupProvider } from '../Form/SetupProvider'

const UnitListing = lazy(() => import('./UnitListing'))
const UnitSetupEditForm = lazy(() => import('./UnitSetupEditForm'))
const CarParkUnitSetupForm = lazy(() => import('../Form/CarParkUnitSetupForm'))
const ImportUnitForm = lazy(() => import('../ImportUnit'))

export const UNIT_PATH = {
  UNIT_LISTING: 'unit-listing',
  IMPORT_EXCEL: 'unit-import',
  SETUP_FORM: 'unit-setup-form',
}

const unitSetupRoutes: RouteObject[] = [
  {
    path: 'unit-listing',
    element: <UnitListing />,
  },
  {
    path: 'unit-import',
    element: <ImportUnitForm title={'Unit Setup'} />,
  },
  {
    path: 'unit-setup-form-edit',
    element: <UnitSetupEditForm />,
  },
  {
    path: 'unit-setup-form',
    element: (
      <SetupProvider>
        <Outlet />
      </SetupProvider>
    ),
    children: [
      {
        path: '',
        element: <CarParkUnitSetupForm isCarpark={false} />,
      },
    ],
  },
]

export default unitSetupRoutes
