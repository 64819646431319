import { ColumnFlex } from '@ifca-ui/core'
import { Typography } from '@mui/material'
import { useConfirmDialog } from 'components/Dialogs/useConfirmDialog'
import { CardListHeader } from 'components/General/CardListItem'
import useAppData from 'components/General/useAppData'
import {
  ProgressBillingDataList,
  useReversalProgressBillingMutation,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import React, { useState } from 'react'
import PBexpansionInstallmentSummary from './PBexpansionInstallmentSummary'
import PBexpansionSummary from './PBexpansionSummary'

interface Props {
  isPA: boolean
  type: string
  pbListData: ProgressBillingDataList['sale_subproduct']
  refetchData?: any
}

export const PBtypeList = ({ isPA, type, pbListData, refetchData }: Props) => {
  const { saleID, navTo, projectID } = useAppData()
  const { showConfirmDialog, ConfirmDialogX } = useConfirmDialog()
  const [reversalId, setReversalId] = useState([])

  const [reversalProgressBilling] = useReversalProgressBillingMutation({
    onCompleted: data => {
      setSnackBar(SystemMsgs.reverseRecord())
      refetchData()
    },
  })

  const handleReversal = item => {
    if (item.allow_reversal && !item.isReversal && isPA) {
      let reversalIds
      item.installmentData
        ? item.installmentData.map(data => data.id).filter(Boolean)
        : (reversalIds = item.id)
      setReversalId(reversalIds)
      showConfirmDialog()
    }
  }

  const reversalData = (id: string[]) => {
    const progressBillingInput = id?.map(el => {
      return {
        saleId: saleID,
        progressBillingId: el,
      }
    })
    reversalProgressBilling({
      variables: { input: progressBillingInput, removeGenerationBatch: true },
    }) // Pass the object to the function
  }

  return (
    <>
      <ConfirmDialogX
        title={'Progress Billing'}
        bodyText={'Are you sure want to reversal?'}
        rightTopTitle={'Reversal'}
        action={() => {
          reversalData(reversalId)
        }}
      />
      <div>
        {pbListData?.length >= 1 &&
          pbListData.map((AddOnRef, index) => {
            return (
              <div key={index}>
                <CardListHeader
                  title={
                    <Typography
                      className="text-xsTitle"
                      color="#FF9800"
                      component="span"
                    >
                      {AddOnRef?.titleBar}
                    </Typography>
                  }
                />
                <ColumnFlex gap={'5px'}>
                  {AddOnRef?.PBdata?.map((item, index) => {
                    let haveSubData = item.installmentData?.length > 0
                    return (
                      <React.Fragment key={item.id}>
                        {haveSubData ? (
                          <PBexpansionInstallmentSummary
                            PBdata={item}
                            isPA={isPA}
                            handleReversal={handleReversal}
                            lastIndex={1}
                            index={index}
                            key={index}
                          />
                        ) : (
                          <PBexpansionSummary
                            PBdata={item}
                            isPA={isPA}
                            handleReversal={handleReversal}
                            lastIndex={1}
                            index={index}
                            key={index}
                          />
                        )}
                      </React.Fragment>
                    )
                  })}
                </ColumnFlex>
              </div>
            )
          })}
      </div>
    </>
  )
}
