// AppState.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react'

// Define the AppState interface without setAppState
export interface ExpansionFilterState {
  expand: boolean
  state?: 'initial' | 'submit' | 'reset' | 'error' | 'success'
}

// Define a new type that includes setAppState
interface ExpansionFilterContextType {
  efState: ExpansionFilterState
  setEfState: React.Dispatch<React.SetStateAction<ExpansionFilterState>>
}

// Initialize the context with an undefined default
const EfStateContext = createContext<ExpansionFilterContextType | undefined>(
  undefined
)

// Create the AppStateProvider component
export const ExpFilStateProvider = ({ children }: { children: ReactNode }) => {
  const [efState, setEfState] = useState<ExpansionFilterState>({
    expand: false,
    state: 'initial',
  })

  // Provide both appState properties and the setAppState function
  return (
    <EfStateContext.Provider value={{ efState, setEfState }}>
      {children}
    </EfStateContext.Provider>
  )
}

// Custom hook to use AppState
export const useExpansionFilterState = () => {
  const context = useContext(EfStateContext)
  if (context === undefined) {
    throw new Error('useAppState must be used within an AppStateProvider')
  }
  return context
}
