import {
  Container,
  EmptyState,
  FloatButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  SearchBar,
  SelectionBar,
  useMenuOption,
  SubContainerX,
} from '@ifca-ui/core'
import { Add, MoreVert } from '@mui/icons-material'
import LockIcon from '@mui/icons-material/Lock'
import { Box, IconButton, Typography } from '@mui/material'
import { getUserProfile } from 'UserData'
import { Dialog } from 'components/Dialogs/Dialog'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  CommonStatus,
  useActivateBillItemMutation,
  useDeactivateBillItemMutation,
  useDeleteBillItemMutation,
  useGetBillItemListQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { Filter } from 'helpers/SearchFilter'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { BillItemsDialog } from './BillItemsDialog'

const BillItemsListing = (props: any) => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const [search, setSearchInput] = React.useState('')
  let navigate = useNavigate()
  const { navBack, navTo } = useNav()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const open = Boolean(anchorEl)
  const [dialogMode, setDialogMode] = useState<any>('')
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const [addInfoDialog, setAddInfoDialog] = React.useState(false)

  const selectionRef = useRef(null)
  const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(null)
  const handleStatusClick = (event: React.MouseEvent<HTMLElement>) => {
    setStatusAnchorEl(event.currentTarget)
  }
  const statusMenuOpen = Boolean(statusAnchorEl)
  const handleStatusMenuClose = () => {
    setStatusAnchorEl(null)
  }

  const [filterStatus, setFilterStatus] = useState('ALL')

  const handleStatusMenuItemClick = status => {
    setFilterStatus(status)
    setStatusAnchorEl(null)
  }

  //--Start GraphQL--
  const {
    loading,
    refetch,
    data: { getBillItemList } = {
      getBillItemList: [],
    },
  } = useGetBillItemListQuery({
    onCompleted: data => {
      setOriginalListing(data?.getBillItemList)
    },
  })

  const [deleteBillItem, { loading: deleteBillItemLoading }] =
    useDeleteBillItemMutation({
      onError: error => {},
      onCompleted: data => {
        if (data.deleteBillItem) {
          setSnackBar(SystemMsgs.deleteRecord())

          refetch()
        }
      },
    })

  const [deactivateBillItem, { loading: deactivateBillItemLoading }] =
    useDeactivateBillItemMutation({
      onError: error => {},
      onCompleted: data => {
        if (data.deactivateBillItem) {
          setSnackBar(SystemMsgs.deactiveRecord())

          refetch()
        }
      },
    })

  const [activateBillItem, { loading: activateBillItemLoading }] =
    useActivateBillItemMutation({
      onError: error => {},
      onCompleted: data => {
        if (data.activateBillItem) {
          setSnackBar(SystemMsgs.activeRecord())
          refetch()
        }
      },
    })

  //--End GraphQL--

  var deletebillItem = () => {
    deleteBillItem({
      variables: {
        id: menu?.id,
      },
    })
  }

  var deactivatebillItem = () => {
    deactivateBillItem({
      variables: {
        billItemId: menu.id,
      },
    })
  }

  var activatebillItem = () => {
    activateBillItem({
      variables: {
        billItemId: menu.id,
      },
    })
  }

  const addDialog = mode => {
    setAddInfoDialog(true)
    setDialogMode(mode)
  }

  const handleDialogClosed = () => {
    setAddInfoDialog(false)
    setDialogMode('')
  }

  //#endregion

  //#region delete dialog
  const [deleteDialog, setDeleteDialog] = React.useState(false)
  const DeleteDialog = (
    <Dialog
      useDefaultDialogHeader={true}
      useDefaultDialogBody={true}
      open={deleteDialog}
      defaultDialogData={{
        header: {
          title: {
            leftTopTitle: {
              title: {
                text: 'Transaction Type',
              },
            },
            rightTopTitle: {
              text: 'Delete',
            },
          },
        },
        bodyText: 'Are you sure want to delete?',
      }}
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => setDeleteDialog(false),
          },
          {
            children: 'Confirm',
            color: 'primary',
            type: 'button',
            onClick: () => {
              deletebillItem()
              setDeleteDialog(false)
            },
          },
        ],
      }}
    />
  )
  //#endregion

  //#region header/subheader
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${rootState.profileData?.subscriptionName}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Transaction Type',
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  //#endregion

  //--enum start--
  const filterListing = [
    {
      id: 'ALL',
      label: 'All',
    },
    {
      id: 'AREA_VARIANCE',
      label: 'Area Variance',
    },
    {
      id: 'FORFEITURE',
      label: 'Forfeiture',
    },
    {
      id: 'INTEREST',
      label: 'Interest',
    },
    {
      id: 'LAD',
      label: 'Lad',
    },
    {
      id: 'PROGRESS_BILLING',
      label: 'Progress Billing',
    },
    {
      id: 'REDEMPTION_RECEIPT',
      label: 'Redemption Receipt',
    },
    {
      id: 'MISC',
      label: 'Misc',
    },
    {
      id: 'REBATE',
      label: 'Rebate',
    },
    {
      id: 'CONTRA',
      label: 'Contra',
    },
  ]
  //--enum end--

  const filterCondt = list => {
    const filter = list?.filter(e => {
      if (filterStatus === 'ALL') {
        return e
      } else {
        return e?.billItemType === filterStatus.toUpperCase()
      }
    })

    return filter
  }
  // console.log('md', menu?.data)

  return (
    <>
      {DeleteDialog}
      <BillItemsDialog
        open={addInfoDialog}
        handleDialogClose={handleDialogClosed}
        refetch={refetch}
        mode={dialogMode}
        billItemId={menu?.id}
        menuData={menu?.data}
        setOpen={setAddInfoDialog}
        setDialogMode={setDialogMode}
      />
      <SubContainerX>
        <Box width="100%" display="flex">
          <Box width="70%">
            <SearchBar
              title={
                <Typography
                  component="span"
                  className="text-mdLabel"
                  variant="inherit"
                >
                  {`Transaction Type Listing `} (
                  <Typography
                    component="span"
                    variant="inherit"
                    className="search-bar-count-f"
                  >
                    {`${
                      Filter(filterCondt(filteredList), search)?.length ?? 0
                    }`}
                  </Typography>
                  )
                </Typography>
              }
              searchInput={{
                value: search,
                onChange: e => {
                  setSearchInput(e.target.value)
                  handleSearch(e.target.value, ['name'])
                },
                onChangeClearInput: () => {
                  setSearchInput('')
                  handleSearch('', ['name'])
                },
              }}
            />
          </Box>

          <Box width="30%" pl="6px" ref={selectionRef}>
            <SelectionBar
              title={
                <Typography
                  component="span"
                  className="text-mdLabel"
                  variant="inherit"
                >
                  {filterListing?.find(el => el.id == filterStatus)?.label}
                </Typography>
              }
              selectionItem={{
                onClick: e => handleStatusClick(e),
              }}
            />
          </Box>
        </Box>
      </SubContainerX>
      <>
        <Container>
          <List>
            {getBillItemList === undefined || getBillItemList?.length === 0 ? (
              <EmptyState subTitle="Click add button to insert new records" />
            ) : (
              filterCondt(filteredList)?.map((x, i) => {
                return (
                  <ListItem
                    // key={x}
                    className={
                      x?.commonStatus == 'INACTIVE' ? 'inactive-list' : ''
                    }
                    secondaryAction={
                      <IconButton onClick={e => handleClick(e, x.id, i, x)}>
                        <MoreVert />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          component="div"
                          display="flex"
                          variant="inherit"
                          color="common.black"
                        >
                          <Typography
                            component="span"
                            className="text-xsTitle"
                            variant="inherit"
                            flex="1"
                          >
                            {x.name}
                          </Typography>
                          {x.systemUse == true ? (
                            <LockIcon
                              style={{ width: '15px', height: '15px' }}
                            />
                          ) : null}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography
                            component="div"
                            display="flex"
                            variant="inherit"
                            color="common.black"
                          >
                            <Typography
                              component="div"
                              className="text-desc"
                              variant="inherit"
                              color="#454545"
                            >
                              {x?.billItemType == 'MISC'
                                ? 'Misc Billing'
                                : x?.billItemType}
                            </Typography>
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                )
              })
            )}
          </List>
          <FloatButton
            color="primary"
            onClick={() => {
              addDialog('ADD')
            }}
          >
            <Add />
          </FloatButton>
        </Container>

        <Menu
          id="default-menu"
          divRef={selectionRef}
          anchorEl={statusAnchorEl}
          open={statusMenuOpen}
          onClose={handleStatusMenuClose}
          MenuListProps={{
            role: 'listbox',
          }}
        >
          {filterListing?.map((el, index) => (
            <MenuItem
              key={index}
              onClick={e => handleStatusMenuItemClick(el.id)}
            >
              {el.label}
            </MenuItem>
          ))}
        </Menu>

        <Menu
          id="default-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
        >
          {menu?.data?.commonStatus === CommonStatus.Active && (
            <MenuItem
              onClick={() => {
                addDialog('EDIT')
              }}
            >
              Edit
            </MenuItem>
          )}
          {menu?.data?.isExistInTransactionData?.length > 0 &&
            menu?.data?.commonStatus === CommonStatus.Active &&
            menu?.data?.systemUse == false && (
              <MenuItem
                onClick={() => {
                  // setIsDeletable(false)
                  deactivatebillItem()
                  handleClose()
                }}
              >
                Deactivate
              </MenuItem>
            )}

          {menu?.data?.isExistInTransactionData?.length > 0 &&
            menu?.data?.commonStatus === CommonStatus.Inactive && (
              <MenuItem
                onClick={() => {
                  // setIsDeletable(false)
                  activatebillItem()
                  handleClose()
                }}
              >
                Activate
              </MenuItem>
            )}

          {menu?.data?.isExistInTransactionData?.length === 0 &&
            menu?.data?.systemUse == false && (
              <MenuItem
                onClick={() => {
                  // setIsDeletable(true)
                  setDeleteDialog(true)
                  handleClose()
                }}
              >
                Delete
              </MenuItem>
            )}
        </Menu>
      </>
    </>
  )
}

export default BillItemsListing
