import {
  ColumnFlex,
  Expansion,
  ExpansionSummary,
  Menu,
  MenuItem,
  RowFlex,
  useMenuOption,
} from '@ifca-ui/core'
import { PxHumanArrow } from '@ifca-ui/icons/dist/index.esm'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import IconX from 'components/General/IconX'
import TextX from 'components/General/TextX'
import TooltipX from 'components/General/TooltipX'
import UIlabelX from 'components/General/UILabelX'
import { PBdata } from 'generated/graphql'
import { useState } from 'react'
import PBexpansionDetails from './PBexpansionDetails'
import PBnumberSquare from './PBnumberSquare'
interface PBexpansionSummaryProps {
  PBdata: PBdata
  isPA: boolean
  handleReversal: (item: any) => void
  lastIndex: number
  index: number
}

const PBexpansionInstallmentSummary: React.FC<PBexpansionSummaryProps> = ({
  PBdata,
  isPA,
  handleReversal,
  index,
}: PBexpansionSummaryProps) => {
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <Expansion className={PBdata.isReversal ? 'inactive-list' : ''}>
      <ExpansionSummary onClick={e => setIsExpanded(!isExpanded)}>
        <RowFlex fullWidth crossAxisAlignment="center" gap="20px">
          <ColumnFlex gap={'4px'} fullWidth className="text-desc text-icon">
            <RowFlex
              key="firstLine"
              mainAxisAlignment="space-between"
              fullWidth
            >
              <RowFlex key="left" gap={'4px'}>
                <PBnumberSquare data={PBdata} />
                {/* <TextX data={PBdata?.title} /> */}
                <UIlabelX
                  children={PBdata?.title}
                  fontWeight={'font-light'}
                  fontSize={'text-[12px]'}
                />
              </RowFlex>
              <RowFlex key="right" className="c-orange">
                {PBdata?.is_stakeholder && <IconX icon={PxHumanArrow} />}
                <TooltipX title="Document Amount" placement="left">
                  <TextX
                    data={PBdata?.sumOrangeAmount}
                    dataType={'Number'}
                    className="text-icon"
                    color="primary"
                  />
                </TooltipX>
              </RowFlex>
            </RowFlex>
            <RowFlex
              key="secondLine"
              mainAxisAlignment="space-between"
              fullWidth
            >
              <div></div>
              <div style={{ color: 'red' }}>
                <RowFlex key="right">
                  <TooltipX title="Outstanding Amount" placement="left">
                    <TextX
                      data={PBdata?.sumRedAmount}
                      dataType={PBdata?.sumRedAmount ? 'Number' : 'Text'}
                      color="red"
                    />
                  </TooltipX>
                </RowFlex>
              </div>
            </RowFlex>
            <RowFlex mainAxisAlignment="space-between" fullWidth>
              <div></div>
              <RowFlex gap="4px">
                <div>No. of installment :</div>
                <div className="underline c-orange">
                  {PBdata?.installmentData.length}
                </div>
              </RowFlex>
            </RowFlex>
          </ColumnFlex>
          <ColumnFlex gap="5px">
            {isPA && !PBdata.isVoid && (
              <IconX
                icon={MoreHorizIcon}
                onClick={e => {
                  e.stopPropagation()
                  handleClick(e, PBdata.id, index, PBdata)
                }}
              />
            )}

            <IconX icon={isExpanded ? ExpandLessIcon : ExpandMoreIcon}></IconX>
          </ColumnFlex>

          <Menu
            id="menu-list"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={e => {
              e.stopPropagation()
              handleClose()
            }}
          >
            {menu.data?.showReversal ? (
              <MenuItem onClick={handleReversal}>
                <span className="">Reversal</span>
              </MenuItem>
            ) : (
              <MenuItem disabled={true}>
                <span className="">Reversal</span>
              </MenuItem>
            )}
          </Menu>
        </RowFlex>
      </ExpansionSummary>
      <PBexpansionDetails installmentData={PBdata.installmentData} />
    </Expansion>
  )
}

export default PBexpansionInstallmentSummary
