import { Clear } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useState } from 'react'
import { Control, Controller } from 'react-hook-form'

interface mobileDatePickerProps {
  name: string
  label?: string
  control: Control<any, any>
  required?: boolean
  error?: boolean
  helperText?: string
  showDelete?: boolean
  showToolbar?: boolean // show edit pen
  variant?: 'standard' | 'outlined' | 'filled'
  margin?: 'normal' | 'dense' | 'none'
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
  style?: React.CSSProperties
  textFieldClassName?: string
  placeholder?: string
  onChange?: (date: Date) => void
  defaultValue?: Date
}

export const DatePickerX = ({
  name,
  label,
  control,
  required = false,
  error = false,
  helperText = '',
  showDelete = false,
  showToolbar = true,
  variant = 'standard',
  margin = 'normal',
  minDate,
  maxDate,
  disabled = false,
  style,
  textFieldClassName,
  placeholder,
  onChange: customOnchange,
  defaultValue = null,
}: mobileDatePickerProps) => {
  const [num, setNum] = useState('0')
  const [tempDate, setTempDate] = useState<Date>()

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { value, onChange, onBlur } }) => (
          <MobileDatePicker
            label={label}
            key={num}
            value={tempDate || value}
            inputFormat="dd/MM/yyyy"
            toolbarFormat="dd/MM/yyyy"
            onChange={value => setTempDate(value)}
            onAccept={value => {
              onChange(value)
              setTempDate(null)
              if (customOnchange) customOnchange(value)
            }}
            minDate={minDate}
            maxDate={maxDate}
            showToolbar={showToolbar}
            disabled={disabled}
            onClose={() => setTempDate(null)}
            onOpen={() => setTempDate(value)}
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  autoComplete="off"
                  name={name}
                  className={textFieldClassName}
                  onChange={_ => {}}
                  onBlur={onBlur}
                  value={value}
                  fullWidth
                  margin={margin}
                  variant={variant}
                  placeholder={placeholder}
                  required={required}
                  error={error}
                  helperText={helperText}
                  disabled={disabled}
                  style={style}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {value != null && showDelete && (
                          <IconButton
                            onClick={e => {
                              e.stopPropagation()
                              setNum(Math.random().toString())
                              onChange(null)
                            }}
                          >
                            <Clear />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}
