import { lazy } from 'react'
import { Outlet, RouteObject } from 'react-router'
import { SetupProvider } from '../Form/SetupProvider'

const CarParkListing = lazy(() => import('./CarParkListing'))
const CarParkSetupEditForm = lazy(() => import('./CarParkSetupEditForm'))
const CarParkUnitSetupForm = lazy(() => import('../Form/CarParkUnitSetupForm'))
const ImportUnitForm = lazy(() => import('../ImportUnit'))

export const CAR_PARK_PATH = {
  CAR_PARK_LISTING: 'car-park-listing',
  IMPORT_EXCEL: 'car-park-import',
  SETUP_FORM: 'car-park-setup-form',
}

const carParkSetupRoutes: RouteObject[] = [
  {
    path: 'car-park-listing',
    element: <CarParkListing />,
  },
  {
    path: 'car-park-import',
    element: <ImportUnitForm title="Car Park Setup" />,
  },
  {
    path: 'car-park-setup-form-edit',
    element: <CarParkSetupEditForm />,
  },
  {
    path: 'car-park-setup-form',
    element: (
      <SetupProvider>
        <Outlet />
      </SetupProvider>
    ),
    children: [
      {
        path: '',
        element: <CarParkUnitSetupForm isCarpark={true} />,
      },
    ],
  },
]

export default carParkSetupRoutes
