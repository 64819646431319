import { MenuItem } from '@ifca-ui/core'
import { InputAdornment, Select, TextField, Typography } from '@mui/material'
import { disableLabelAutocomplete } from 'helpers/StringNumberFunction/formatLabel'
import {
  CountryIso2,
  FlagImage,
  defaultCountries,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone'
import 'react-international-phone/style.css'

interface phoneProps {
  onChange: (...event: any[]) => void
  onBlur?: (...event: any[]) => void
  value: string
  label: any
  required?: boolean
  error?: boolean
  errorText?: string
  variant?: 'standard' | 'filled' | 'outlined'
}

export const CustomPhoneInput = ({
  onBlur,
  onChange,
  value,
  label,
  required = false,
  error = false,
  errorText,
  variant = 'standard',
}: phoneProps) => {
  if (!value) {
    value = ''
  }

  const { phone, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: 'my',
      value,
      countries: defaultCountries,
      onChange: data => {
        let newValue = data.phone
        if (newValue) {
          // newValue = newValue.replace(/[^a-zA-Z0-9+\s]/g, '')
          // newValue = newValue.replace(/\s/g, '')
        }
        onChange(newValue)
      },
    })

  return (
    <TextField
      variant={variant}
      label={disableLabelAutocomplete(label, 5)}
      color="primary"
      // placeholder="Phone number"
      value={phone}
      onBlur={onBlur}
      onChange={handlePhoneValueChange}
      type="tel"
      autoComplete="off"
      required={required}
      inputRef={inputRef}
      margin="normal"
      fullWidth
      error={error}
      helperText={errorText}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ marginRight: '2px', marginLeft: '-8px' }}
          >
            <Select
              // MenuProps={{
              //   style: {
              //     height: '300px',
              //     width: '360px',
              //     top: '10px',
              //     left: '-34px',
              //   },
              //   transformOrigin: {
              //     vertical: 'top',
              //     horizontal: 'left',
              //   },
              // }}
              sx={{
                width: 'max-content',
                // Remove default outline (display only on focus)
                fieldset: {
                  display: 'none',
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: 'block',
                  },
                },
                // Update default spacing
                '.MuiSelect-select': {
                  padding: '8px',
                  paddingRight: '24px !important',
                },
                svg: {
                  right: 0,
                },
              }}
              value={country.iso2}
              onChange={e => setCountry(e.target.value as CountryIso2)}
              renderValue={value => (
                <FlagImage iso2={value} style={{ display: 'flex' }} />
              )}
            >
              {defaultCountries.map(c => {
                const country = parseCountry(c)
                return (
                  <MenuItem key={country.iso2} value={country.iso2}>
                    <FlagImage
                      iso2={country.iso2}
                      style={{ marginRight: '8px' }}
                    />
                    <Typography marginRight="8px">{country.name}</Typography>
                    <Typography color="gray">+{country.dialCode}</Typography>
                  </MenuItem>
                )
              })}
            </Select>
          </InputAdornment>
        ),
      }}
    />
  )
}

export const formatPhoneNumber = (value?: any) => {
  if (value) {
    value = value.replace(/[^a-zA-Z0-9+\s]/g, '')
    value = value.replace(/\s/g, '')
  }

  return value
}
