import React from 'react'

interface ThisProps {
  children?: React.ReactNode
  fontSize?:
    | `text-[${string}]`
    | 'text-xs'
    | 'text-sm'
    | 'text-base'
    | 'text-lg'
    | 'text-xl'
    | 'text-2xl'
    | 'text-3xl'
    | 'text-4xl'
    | 'text-5xl'
  fontWeight?:
    | 'font-thin'
    | 'font-extralight'
    | 'font-light'
    | 'font-normal'
    | 'font-medium'
    | 'font-semibold'
    | 'font-bold'
    | 'font-extrabold'
    | 'font-black'
  textAlign?: 'text-left' | 'text-center' | 'text-right' | 'text-justify'
  color?:
    | 'text-black'
    | 'text-white'
    | 'text-gray-500'
    | 'text-gray-700'
    | 'text-red-500'
    | 'text-blue-500'
    | 'text-green-500'
    | 'text-yellow-500'
    | string
  overflow?:
    | 'whitespace-normal'
    | 'truncate'
    | 'text-clip overflow-hidden whitespace-nowrap'
}

/**
 * UIlabelX is a customizable label component that applies various Tailwind CSS utility classes
 * to the text content. You can easily control the font size, weight, alignment, and color.
 *
 * @param children - The text or content to be displayed inside the label.
 * @param fontSize - The font size of the label. Options include:
 *    - 'text-xs': Extra small text
 *    - 'text-sm': Small text
 *    - 'text-base': Default text size (small title)
 *    - 'text-lg': Large text (medium title)
 *    - 'text-xl': Extra large text (big title)
 *    - 'text-2xl', 'text-3xl', etc., for larger sizes.
 * @param fontWeight - Controls the weight of the font. Tailwind options include:
 *    - 'font-normal', 'font-bold', 'font-medium', 'font-light', etc.
 * @param textAlign - Aligns the text within its container. Options include:
 *    - 'text-left', 'text-center', 'text-right', 'text-justify'.
 * @param color - Controls the text color. Tailwind provides many options (e.g., 'text-black', 'text-red-500', etc.).
 *
 * @param overflow - Controls how text overflow is handled. Options include:'
 *   - 'whitespace-normal': Normal whitespace handling
 *   - 'truncate': Truncate text with ellipsis
 *   - 'text-clip overflow-hidden whitespace-nowrap': Clip text with ellipsis
 *
 *
 * @returns A styled span element containing the text passed as children.
 */
const UIlabelX: React.FC<ThisProps> = ({
  children,
  fontSize = 'text-[11px]',
  fontWeight = 'font-normal',
  textAlign = 'text-left',
  color = 'text-black',
  overflow = 'whitespace-normal',
}) => {
  return (
    <div
      className={`${fontSize} ${fontWeight} ${textAlign} ${color} ${overflow}`}
    >
      {children ?? '-'}
    </div>
  )
}

export default UIlabelX
