import { Container, ListItem } from '@ifca-ui/core'
import { KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import useNav from 'components/RouteService/useNav'
import useHeader from 'helpers/Hooks/useHeader'

// import { UIGalleryApp } from '@property-ui/core'
import { useEffect } from 'react'

const ShowcaseItems = [
  {
    name: 'Custom Table',
    link: 'custom-table',
  },
]

const DevTestingListing = () => {
  const { navTo } = useNav()
  //#region header/subheader
  useHeader({
    current: 'Dev Testing Listing',
    maxItems: 1,
    icon: '',
    backPath: '',
    title: 'projectName',
  })
  //#endregion

  // return <UIGalleryApp contentOnly={true} />

  return (
    <Container>
      {ShowcaseItems.map(x => {
        return (
          <ListItem
            secondaryAction={
              <IconButton>
                <KeyboardArrowRightOutlinedIcon />
              </IconButton>
            }
            onClick={e => {
              navTo(`/dev-testing/${x.link}`)
            }}
          >
            {x.name}
          </ListItem>
        )
      })}
    </Container>
  )
}

export default DevTestingListing
