import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const AreaVarianceListing = lazy(() => import('./AreaVarianceListing'))
const AreaVarianceForm = lazy(() => import('./AreaVarianceForm'))
const AreaVarianceDetail = lazy(() => import('./AreaVarianceDetail'))

const AreaVarianceRoutes: RouteObject[] = [
  {
    path: 'area-variance',
    children: [
      {
        path: '',
        element: <AreaVarianceListing />,
      },
      // {
      //   path: 'area-variance/detail/:id',
      //   element: <AreaVarianceDetail />,
      // },
      // {
      //   path: 'area-variance/add',
      //   element: <AreaVarianceForm mode="add" />,
      // },
    ],
  },
]

export default AreaVarianceRoutes
